<template>
  <div class="notice" @click="myDate($event)">
    <div class="notice-left">
      <img :src="comObj.bdobj.imgUrl" alt style="width:18px;" />
    </div>
    <div class="uptext">
      <!--向左-->
      <marquee v-if="comObj.bdobj.direction==2?1:0" class="marquee">
        <span v-for="(item, index) in Alldata" :key="index">{{item.title}}</span>
      </marquee>
      <!--向上-->
      <div class="headlines" :style="{'display':comObj.bdobj.direction==2?'none':''}">
        <div class="headlines-main">
          <ul class="marquee_list" :class="{marquee_top:animate}">
            <li v-for="(item, index) in Alldata" :key="index">
              <span>{{item.title}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["comObj", "nowComIdx"],
  computed: {
    ...mapGetters(["bdobj"])
  },
  data() {
    return {
      Alldata: [
        {
          title: "我的第一个新闻"
        },
        {
          title: "我的第二个新闻"
        },
        {
          title: "我的第三个新闻"
        }
      ],
      animate: true,
      timing: null
    };
  },
  methods: {
    showMarquee() {
      this.animate = true;
      if (this.Alldata.length !== 0) {
        setTimeout(() => {
          this.Alldata.push(this.Alldata[0]);
          this.Alldata.shift();
          this.animate = false;
        }, 500);
      } else {
        return;
      }
    },
  },
  watch: {
    bdobj: {
      handler(oldVal, newVal) {
        if (parseInt(newVal.direction) === 1) {
          this.timing = setInterval(this.showMarquee, 3000);
        } else if (parseInt(newVal.direction) === 2) {
          clearInterval(this.timing);
        }
      },
      deep: true
    }
  },
  created() {
    console.log(this.bdobj, this.comObj)
    if (Number(this.bdobj.direction) === 1) {
      this.timing = setInterval(this.showMarquee, 3000);
    }
  }
};
</script>
<style scoped>
.notice {
  width: 100%;
  display: flex;
  height: 50px;
  padding: 0 20px;
}
.notice-left {
  width: 30px;
  /* line-height: 50px; */
  height: 50px;
  padding: 16px 0;
}
.notice-left > img {
  width: 300px;
}
.uptext {
  width: 300px;
  font-size: 14px;
  color: #353535;
  overflow: hidden;
}
.marquee {
  height: 50px;
  line-height: 50px;
}
.marquee-left span {
  margin-left: 15px;
}
.headlines {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
}

.headlines-main {
  width: 300px;
  height: 50px;
  line-height: 50px;
  color: #737373;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.marquee_list {
  padding-left: 0;
  margin: 0;
  width: 300px;
  display: block;
  top: 0;
  left: 0;
  height: 50px;
}
.marquee_top {
  transition: all 0.5s;
  margin-top: -50px;
}
.marquee_list li {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 0.28rem;
}
</style>
